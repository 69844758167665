<template>
  <div class="login-container">
    <div class="auth-body">
      <div class="dashboard-card login-card">
        <div class="image-container hidden-mobile">
          <login-icon />
        </div>
        <div>
          <h4>
            Forgot Password? 🔒
          </h4>
          <p class="mb-3">
            Enter your email and we'll send you instructions to reset your password
          </p>

          <!-- form -->
          <validation-observer ref="forgotPasswordForm">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="d-flex align-items-center justify-content-between">
                <b-link :to="{name:'login'}"  class="lab-text-info">
                  <feather-icon icon="ChevronLeftIcon" /> Back to login
                </b-link>
                <button class="lab-btn lab-btn-info" type="submit" :disabled="$store.getters.getLoader">
                  <spinner-loader />
                  Send reset link
                </button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import store from '@/store/index'

import AlertService from '@/common/alert.service'
import LoginIcon from '@/layouts/icons/store/LoginIcon.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    LoginIcon,
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('account/sendResetPassowrdEmail', { emailAddress: this.userEmail })
            .then(response => {
              if (response.statusCode === 200) {
                AlertService.success(response.result);
              } else {
                AlertService.error(response.message)
              }
            });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style lang="scss" scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .auth-body {
    padding: 2.5rem;
    max-width: 1020px;
  }
 .login-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    .image-container {
      margin-right: 1rem;
      padding: 2rem;
    }
    h4 {
      color: var(--lab-black);
    }

    .form-container {
      width: 500px;

      .forgot-password {
        font-size: 0.8rem;
        a {
          color: var(--lab-minted-elegance-color);
        }
      }
    }
  }

  .create-account {
    color: var(--lab-steel);
    font-size: 0.8rem;
    text-align: center;
    margin-top: 3rem;
    a {
      color: var(--lab-minted-elegance-color);
    }
  }

  .footer-container {
    .btn {
      padding: 0.6rem !important;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .btn-linkedin {
      background-color: #1608d8;
      border-color: #fff;
      border: none;
    }
    .btn-reddit {
      background-color: #FF4500;
      border-color: #fff;
      border: none;
    }
  }

  .verify-container {
    .info-desc, .alert-body {
      font-size: 0.85rem;
    }
    .remaining-time {
      span {
        color: var(--lab-steel);
        font-size: 0.9rem;
      }

      .send-again {
        color: var(--lab-minted-elegance-color);
        cursor: pointer;
      }
    }
  }

  .input-group {
    box-shadow: none !important;
  }
  .form-control:focus {
    border: 2px solid var(--lab-minted-elegance-color);
  }

  .login-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1079px) {
  .form-container {
    width: 300px !important;
  }
  .login-bottom {
    display: block !important;
  }
  .login-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 150px;
    }
  }
}
</style>
